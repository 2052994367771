import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "~components/layout/full"
import Pagination from "~components/pagination"
import Seo from "~components/seo"
import { FormattedDate } from "react-intl"
import Help from "~components/help"

export const query = graphql`
  query($offset: Int!, $limit: Int!) {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/blog/" } }
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: $limit
      skip: $offset
    ) {
      edges {
        node {
          frontmatter {
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 144, maxHeight: 108) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            title
            date(formatString: "x")
            path
          }
        }
      }
    }
  }
`

export default ({ data, pageContext, location }) => (
  <Layout>
    <Seo title="Blog" canonical={location.pathname} />

    <section className="c-page l-container">
      <header className="c-page__header">
        <div className="c-page__header-box">
          <h1 className="l-headline--primary">Blog</h1>
        </div>
      </header>
    </section>

    <div className="c-blog l-container">
      <div className="c-articles">
        {data.allMarkdownRemark.edges.map((edge, key) => (
          <Link to={edge.node.frontmatter.path} className="c-article" key={key}>
            {edge.node.frontmatter.thumbnail &&
            edge.node.frontmatter.thumbnail.childImageSharp ? (
              <Img
                fluid={edge.node.frontmatter.thumbnail.childImageSharp.fluid}
                className="c-article__img"
              />
            ) : (
              <div className="c-article__img"></div>
            )}
            <div className="c-article__content">
              <div className="c-article__title">
                {edge.node.frontmatter.title}
              </div>
              <div className="c-article__date">
                <FormattedDate
                  value={new Date(parseInt(edge.node.frontmatter.date, 10))}
                  year="numeric"
                  month="long"
                  day="2-digit"
                />
              </div>
            </div>
          </Link>
        ))}
      </div>

      <Pagination
        offset={pageContext.offset}
        limit={pageContext.limit}
        perPage={pageContext.perPage}
        total={pageContext.total}
      />
    </div>
    <Help />
  </Layout>
)
